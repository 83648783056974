import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import ContactSuccess from '../Organisations/Shared/ContactSuccess';

type Props = {
  leadsLink: String,
};

const useStyles = makeStyles(theme => ({
  finalContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  finalAction: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
}));

const LeadFinalStep = ({ leadsLink }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.layoutContainer}>
      <Grid item md={2} />
      <Grid item md={8} xs={12}>
        <Paper className={classes.finalContainer}>
          <ContactSuccess
            title={t('APP.LEADS.SUCCESS.TITLE')}
            subtitle={t('APP.LEADS.SUCCESS.PROPOSE.SUBTITLE')}
          >
            <Grid container justifyContent="center" className={classes.finalAction}>
              <Button color="primary" component={Link} to={leadsLink}>
                {t('APP.LEADS.SUCCESS.ACTION')}
              </Button>
            </Grid>
          </ContactSuccess>
        </Paper>
      </Grid>
      <Grid item md={2} />
    </Grid>
  );
};

export default LeadFinalStep;
