import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { green, orange } from '@material-ui/core/colors';

import LeadsActions from '../../Redux/LeadsRedux';

import { ASK_FOR } from '../../Services/LeadHelper';
import { getExpertiseStyle } from '../../Themes/Expertises';
import { WelloIcon } from '../../Themes/Icon';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 600,
  },
  serviceIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: theme.spacing(2),
    height: 50,
    width: 50,
    '&>*': {
      height: 50,
      width: 50,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(0),
  },
  button: {
    marginLeft: theme.spacing(1),
    // backgroundColor: theme.current.primary.main,
    // color: theme.palette.common.white,
  },
  constrainedText: {
    wordWrap: 'break-word',
  },
  confirmed: {
    color: green[600],
  },
  pending: {
    color: orange[600],
  },
  icons: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  family: Object,
  lead: Object,
  canShowFinalStep: Object,
};

const LeadHeaderFamily = ({ family, lead, canShowFinalStep }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleUpdateLeadClick = updateAttributes => {
    const updatedLead = { ...lead, ...updateAttributes };
    dispatch(LeadsActions.updateFamilyLeadRequest(family?.admin?.id, updatedLead));
    canShowFinalStep.current = true;
  };

  const defaultTitle =
    lead?.ask_for === ASK_FOR.ME
      ? t('APP.LEADS.DETAILS.TITLE', {
          serviceLabel: lead?.service?.label ? lead?.service?.label : t('LEAD'),
          helperName: `${lead?.helper_first_name} ${lead?.helper_last_name}`,
        })
      : t('APP.LEADS.DETAILS.TITLE2', {
          serviceLabel: lead?.service?.label ? lead?.service?.label : t('LEAD'),
          helpedName: lead?.helped_full_name,
        });

  return (
    <Grid container style={{ alignItems: 'flex-end' }}>
      {/* TITLE PANEL */}
      <Grid item md={8} xs={12}>
        {lead?.service ? (
          <>
            <Typography variant="h3" component="h1" className={classes.bold} itemProp="name">
              <Avatar
                alt={lead?.services?.length > 0 ? lead?.services[0]?.label : ''}
                className={classes.serviceIcon}
                style={{
                  color: lead?.service?.slug
                    ? getExpertiseStyle(lead?.service?.expertise).color
                    : '#ccc',
                  backgroundColor: 'white',
                }}
              >
                {lead?.service?.slug ? (
                  getExpertiseStyle(lead?.service?.expertise).icon
                ) : (
                  <WelloIcon />
                )}
              </Avatar>
              {defaultTitle}
            </Typography>
          </>
        ) : (
          <Typography variant="h3" component="h1" className={classes.bold} itemProp="name">
            {t(`APP.LEADS.${lead?.type}`)}
          </Typography>
        )}
      </Grid>
      {/* REGISTER PANEL */}
      <Grid item md={4} xs={12}>
        <div className={classes.buttonContainer}>
          {lead?.responsible_id && (
            <>
              <Typography
                className={classNames(
                  classes.constrainedText,
                  lead?.validated ? classes.confirmed : classes.pending,
                )}
              >
                {t(
                  lead?.validated
                    ? 'APP.LEADS.STATE.RESPONSIBLE_VALIDATED'
                    : 'APP.LEADS.STATE.RESPONSIBLE_PENDING',
                  { firstName: lead?.responsible?.fullname || lead?.responsible?.first_name },
                )}
              </Typography>
              {!lead?.validated && (
                <>
                  <Button
                    onClick={() => handleUpdateLeadClick({ validated: true })}
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                  >
                    {t('APP.LEADS.ACTION.CONFIRM')}
                  </Button>
                  <Button
                    onClick={() => handleUpdateLeadClick({ responsible_id: null })}
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.button}
                  >
                    {t('APP.LEADS.ACTION.REJECT')}
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default LeadHeaderFamily;
