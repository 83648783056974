import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconDateRange from '@material-ui/icons/DateRange';
import IconMessage from '@material-ui/icons/Message';
import IconArrival from '@material-ui/icons/PinDropRounded';
import IconLocation from '@material-ui/icons/LocationOn';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  listItem: {
    marginRight: '3px',
    paddingLeft: '3px',
    minWidth: '40px',
    color: theme.current.primary,
    // minWidth: '150px',
  },
}));

type Props = {
  lead: Object,
};

function ShowLeadSap(props: Props) {
  const { lead } = props;
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root} spacing={2}>
        {lead.formatted_address && (
          <ListItem>
            <ListItemIcon className={classes.listItem}>
              <IconArrival />
            </ListItemIcon>
            <ListItemText primary={lead.formatted_address} />
          </ListItem>
        )}
        {lead.description && (
          <ListItem>
            <ListItemIcon className={classes.listItem}>
              <IconMessage />
            </ListItemIcon>
            <ListItemText primary={lead.description} />
          </ListItem>
        )}
        {lead.recurrence && (
          <ListItem>
            <ListItemIcon className={classes.listItem}>
              <IconDateRange />
            </ListItemIcon>
            <ListItemText primary={lead.recurrence} />
          </ListItem>
        )}
        {lead.meta.ville && (
          <ListItem>
            <ListItemIcon className={classes.listItem}>
              <IconLocation />
            </ListItemIcon>
            <ListItemText primary={lead.meta.ville} />
          </ListItem>
        )}
      </Grid>
    </>
  );
}

export default ShowLeadSap;
