import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { green, orange } from '@material-ui/core/colors';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import LeadsActions from '../../Redux/LeadsRedux';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import { isServiceTransport, isServiceRepit } from '../../Acl/Controls';
import ShowLeadTransport from '../Organisations/Leads/ShowLeadTransport';
import ShowLeadRepit from '../Organisations/Leads/ShowLeadRepit';
import NoMatch404 from '../NoMatch404';
import CardHoldingSimplified from '../../Components/Organisations/Shared/CardHoldingSimplified';
import LeadComments from '../../Components/Leads/LeadComments';
import LeadHeaderFamily from '../../Components/Leads/LeadHeaderFamily';
import LeadResumeFamily from '../../Components/Leads/LeadResumeFamily';
import LeadFinalStep from '../../Components/Leads/LeadFinalStep';
import LeadDescriptionFamily from '../../Components/Leads/LeadDescriptionFamily';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 600,
  },
  media: {
    objectFit: 'cover',
    maxHeight: '100px',
    borderRadius: '4px',
  },
  avatarCard: {
    padding: theme.spacing(2),
  },
  avatarHeader: {
    padding: theme.spacing(1),
  },
  contactHelper: {
    margin: theme.spacing(1, 0),
  },
  serviceIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: theme.spacing(2),
    height: 50,
    width: 50,
    '&>*': {
      height: 50,
      width: 50,
    },
  },
  headerContainer: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 2px 3px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12)',
  },
  headerLayout: {
    ...LayoutStyles.fullWidthLayout,
    width: '100%',
    padding: '20px',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    width: 'inherit',
    paddingTop: '40px',
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
  },
  leftContent: {
    order: 2,
    [theme.breakpoints.up(`md`)]: {
      order: 1,
    },
  },
  rightContent: {
    order: 1,
    [theme.breakpoints.up(`md`)]: {
      position: 'sticky',
      top: '4rem',
      order: 2,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(0),
  },
  button: {
    marginLeft: theme.spacing(1),
    // backgroundColor: theme.current.primary.main,
    // color: theme.palette.common.white,
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  constrainedText: {
    wordWrap: 'break-word',
  },
  confirmed: {
    color: green[600],
  },
  pending: {
    color: orange[600],
  },
  list: {
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '0px',
  },
  listItem: {
    marginRight: '3px',
    paddingLeft: '3px',
    minWidth: '40px',
  },
  icons: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  finalContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  finalAction: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  buttonLink: {
    textTransform: 'none',
    padding: theme.spacing(0, 0, 2),
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}));

type Props = {
  family: Object,
};

const ShowLead = ({ family }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { lead_id: leadId } = match.params;
  const lead = useSelector(state => state.leads.lead);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const isFetching = useSelector(state => state.leads.isFetching.updateFamilyLead);
  const isFetchingComment = useSelector(
    state => state.leads.isFetching.updateOrganisationLeadAndPostComment,
  );
  const errors = useSelector(state => state.leads.errors.getFamilyLead);
  const canShowFinalStep = React.useRef(false);
  const [showFinalStep, setShowFinalStep] = React.useState(false);

  const isTransport = isServiceTransport(lead?.service);
  const isRepit = isServiceRepit(lead?.service);

  React.useEffect(() => {
    dispatch(LeadsActions.getFamilyLeadRequest(family?.admin?.id, leadId));
    setShowFinalStep(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId]);

  React.useEffect(() => {
    if (canShowFinalStep.current && !isFetching && !isFetchingComment && !errors)
      setShowFinalStep(true);
  }, [isFetching, isFetchingComment, errors]);

  if (errors && errors.status === 404) return <NoMatch404 />;

  if (!lead) {
    return null;
  }
  if (showFinalStep) {
    return <LeadFinalStep leadsLink={`/families/${family.slug}/leads`} />;
  }

  return (
    <>
      <Grid container>
        {/* HEADER SECTION */}
        <Grid container className={classes.headerContainer}>
          <Grid className={classes.headerLayout}>
            <Button
              color="primary"
              component={Link}
              to={`/families/${family?.slug}/leads`}
              className={classes.buttonLink}
              disableTouchRipple
            >
              <ArrowLeftIcon /> {t('APP.LEADS.BACK')}
            </Button>
            {lead && (
              <LeadHeaderFamily family={family} lead={lead} canShowFinalStep={canShowFinalStep} />
            )}
          </Grid>
        </Grid>

        {/* CONTENT SECTION */}
        <Grid container reverse spacing={8} className={classes.layout}>
          {/* CONTENT PANEL */}
          <Grid item md={8} xs={12} className={classes.leftContent}>
            {/* DESCRIPTION PANEL */}
            <div className={classes.section}>
              <Typography variant="h4" component="h2" gutterBottom>
                {lead?.service?.label
                  ? t('APP.LEADS.QUALIFIED_TITLE', { leadLabel: lead?.service?.label })
                  : t(`APP.LEADS.TITLE.${lead?.type}`)}
              </Typography>
              <Divider className={classes.divider} />
              {/* TRANSPORT */}
              {isTransport && <ShowLeadTransport lead={lead} />}
              {/* REPIT */}
              {isRepit && <ShowLeadRepit lead={lead} />}
              {/* GENERAL */}
              {lead && <LeadDescriptionFamily lead={lead} />}
              {/* COMMENT PANEL */}
              {lead && isLoggedIn && (
                <LeadComments lead={lead} canShowFinalStep={canShowFinalStep} />
              )}
            </div>
          </Grid>
          {/* RESUME PANEL */}
          <Grid item md={4} xs={12} className={classes.rightContent}>
            {lead &&
              (lead?.service?.performed_by === 'professional' ? (
                <CardHoldingSimplified
                  holding={lead.holding}
                  holdingSlug={lead.holding?.slug}
                  organisationSlug={lead.organisation?.slug}
                  orgaName={lead.organisation.name}
                />
              ) : (
                <LeadResumeFamily lead={lead} />
              ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ShowLead;
