import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconDateRange from '@material-ui/icons/DateRange';
import IconMessage from '@material-ui/icons/Message';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import IconLocation from '@material-ui/icons/LocationOn';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import IconCake from '@material-ui/icons/Cake';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';
import PageviewIcon from '@material-ui/icons/Pageview';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTranslation } from 'react-i18next';
import { getOrgaName } from '../../Services/OrganisationHelper';

const useStyles = makeStyles(() => ({
  listItem: {
    marginRight: '3px',
    paddingLeft: '3px',
    minWidth: '40px',
  },
}));

type Props = {
  lead: Object,
};

const LeadDescriptionFamily = ({ lead }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      {lead?.organisation && (
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <LocationCityIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                {lead?.sharer ? (
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: t('APP.LEADS.SHARED.FAMILY.SHARER_NAME', {
                        sharer: getOrgaName(lead.sharer),
                        orga: getOrgaName(lead?.organisation),
                      }),
                    }}
                  />
                ) : (
                  <ListItemText primary={getOrgaName(lead?.organisation)} />
                )}
              </>
            }
          />
        </ListItem>
      )}
      {lead?.description && (
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <IconMessage />
          </ListItemIcon>
          <ListItemText className={classes.listItem} primary={lead?.description} />
        </ListItem>
      )}
      {lead?.recurrence && (
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <IconDateRange />
          </ListItemIcon>
          <ListItemText className={classes.listItem} primary={lead?.recurrence} />
        </ListItem>
      )}
      {lead.meta?.page && (
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <PageviewIcon />
          </ListItemIcon>
          <ListItemText primary={lead.meta?.page} />
        </ListItem>
      )}
      {lead.meta?.departement && (
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <IconLocation />
          </ListItemIcon>
          <ListItemText primary={lead.meta?.departement} />
        </ListItem>
      )}
      {lead.meta?.ville && (
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <IconLocation />
          </ListItemIcon>
          <ListItemText primary={lead.meta?.ville} />
        </ListItem>
      )}
      {lead.meta?.helper_situation && (
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <FilterVintageIcon />
          </ListItemIcon>
          <ListItemText
            primary={t(
              `APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_SITUATION.${lead.meta?.helper_situation.toUpperCase()}`,
            )}
          />
        </ListItem>
      )}
      {lead.meta?.helper_age && (
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <IconCake />
          </ListItemIcon>
          <ListItemText
            primary={t(
              `APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_AGE.${lead.meta?.helper_age.toUpperCase()}`,
            )}
          />
        </ListItem>
      )}
      {lead.meta?.helper_needs && (
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <ShoppingBasketIcon />
          </ListItemIcon>
          <ListItemText
            primary={t(
              `APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_NEEDS.${lead.meta?.helper_needs.toUpperCase()}`,
            )}
          />
        </ListItem>
      )}
    </div>
  );
};

export default LeadDescriptionFamily;
