/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { isClientSide } from '../../Config/ServerConfig';
import { booleanPointInPolygon, point, polygon as tpolygon } from '@turf/turf';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Alert } from '@mui/material';
import Box from '@material-ui/core/Box';
import Typography from '@mui/material/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  textField: {
    backgroundColor: '#e6e6e6',
    boxShadow: 'none',
    borderRadius: '4px',
    '& .MuiInputBase-input': {
      color: '#000',
    },
  },
}));

type Props = {
  onSelect: Function,
  onClear: Function,
  placeHolder: String,
  defaultValue: String,
  addressTypes: String,
  polygon: Array,
  id: String,
  style: Object,
  bbox: Array,
  geographicLimits: Object,
  cssClass: String,
  countries: String,
  disabled: boolean,
  setQuery: Function,
};

function AutoCompletePlaces({
  onSelect,
  onClear,
  placeHolder,
  defaultValue,
  addressTypes,
  geographicLimits,
  id,
  style,
  cssClass,
  countries,
  disabled,
  setQuery,
}: Props) {
  const classes = useStyles();
  const [showBoundingPolygon, setShowBoundingPolygon] = useState(false);

  if (!isClientSide() || disabled) {
    return (
      <TextField
        label=""
        variant="outlined"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
        }}
        className={classes.textField}
        placeholder={placeHolder}
        disabled={disabled}
      />
    );
  }

  // eslint-disable-next-line global-require
  const L = require('react-leaflet');
  // eslint-disable-next-line global-require
  const { Polygon } = require('react-leaflet');
  // eslint-disable-next-line global-require
  const { SearchBox } = require('@mapbox/search-js-react');

  return (
    <>
      <div id={id} style={style} className={cssClass || 'mapbox-autocomplete-form'}>
        <SearchBox
          options={{
            language: 'fr',
            types: addressTypes || 'place,address,poi',
            bbox: geographicLimits?.bbox,
            proximity: geographicLimits?.proximity,
            countries: countries || 'fr,gp,mq,re,bl,nc,pm,wf,mu,pf,yt,mf,gf, lu',
          }}
          placeholder={placeHolder}
          value={defaultValue}
          onRetrieve={response => {
            const feature = response.features[0];
            if (!feature) return;

            const address =
              (feature.properties.feature_type === 'poi' ? feature.properties.name + ', ' : '') +
              feature.properties.full_address;

            const pol = geographicLimits?.polygon ? tpolygon(geographicLimits.polygon) : null;

            if (
              pol &&
              !booleanPointInPolygon(
                point([
                  feature.properties.coordinates.latitude,
                  feature.properties.coordinates.longitude,
                ]),
                pol,
              )
            ) {
              setShowBoundingPolygon(feature);
              return;
            }

            const result = {
              formatted_address: address,
              coordinates: {
                lat: feature.properties.coordinates.latitude,
                lng: feature.properties.coordinates.longitude,
              },
              locality: feature.properties.context.place.name,
              postcode: feature.properties.context.postcode?.name || '',
              bbox: feature.properties.bbox || [],
            };

            if (setQuery) setQuery(address);
            onSelect(result);
          }}
          onClear={onClear}
          accessToken={process.env.RAZZLE_MAPBOX_GEOCODER_API_KEY}
        />
      </div>
      <Dialog
        open={showBoundingPolygon}
        onClose={() => setShowBoundingPolygon(false)}
        maxWidth={'lg'}
        fullWidth
      >
        <DialogContent>
          <Box p={3}>
            <Alert severity={'warning'}>
              <b>{showBoundingPolygon?.properties?.full_address}</b> ne fait pas partie de la zone
              couverte.
            </Alert>
            <Box pt={3}>
              <Typography variant={'button'} style={{ fontWeight: 'bold' }}>
                Zone couverte
              </Typography>
              <L.MapContainer
                zoom={9}
                bounds={geographicLimits?.polygon[0]}
                style={{ height: '350px' }}
              >
                <L.TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Polygon positions={geographicLimits?.polygon} color="blue" />
              </L.MapContainer>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowBoundingPolygon(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AutoCompletePlaces;
