import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TransmissionPostForm from '../../Posts/TransmissionPostForm';
import TransmissionsActions from '../../../Redux/TransmissionsRedux';
import ToasterActions from '../../../Redux/ToasterRedux';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(2),
  },
  typo: {
    paddingTop: theme.spacing(2),
    textAlign: 'justify',
  },
  buttonContainer: {
    flexDirection: 'column',
    alignItems: 'normal',
  },
  button: {
    backgroundColor: theme.current.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
}));

type Props = {
  lead: Object,
};

const CardLeadTransmissionCreation = ({ lead }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const primary = lead?.ask_for === 'me' ? lead?.helper : lead?.helped;
  const [showTransmissionPostForm, setShowTransmissionPostForm] = React.useState(false);
  const fakeTransmission = {
    expertise_slug: lead?.service?.slug,
    element_id: lead?.id,
    element_type: 'Lead',
  };

  const createdTransmission = useSelector(state => state.transmissions.transmission);
  const transmissionCreationSuccess =
    createdTransmission && createdTransmission.element_id === lead?.id;

  React.useEffect(() => {
    if (createdTransmission) dispatch(TransmissionsActions.resetTransmissions());
    return () => {
      if (createdTransmission) dispatch(TransmissionsActions.resetTransmissions());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (transmissionCreationSuccess) {
      dispatch(
        ToasterActions.showToaster({ id: 'APP.TRANSMISSIONS.LEAD.CREATED' }, 'success', 5000),
      );
      dispatch(TransmissionsActions.resetTransmissions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transmissionCreationSuccess]);

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" component="div">
            {t('APP.TRANSMISSIONS.LEAD.CREATE.TITLE', { value: lead?.responsible?.first_name })}
          </Typography>
          <Typography variant="h6" component="div" className={classes.typo}>
            {t('APP.TRANSMISSIONS.LEAD.CREATE.BODY_1', { value: primary?.first_name })}
          </Typography>
          <Typography variant="body2" className={classes.typo}>
            {t('APP.TRANSMISSIONS.LEAD.CREATE.BODY_2', {
              value: `${lead?.organisation?.holding_name} - ${lead?.organisation?.name}`,
            })}
          </Typography>
        </CardContent>
        <CardActions container className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={classes.button}
            size="small"
            onClick={() => setShowTransmissionPostForm(true)}
          >
            {t('WRITE')}
          </Button>
        </CardActions>
      </Card>
      <Dialog
        open={showTransmissionPostForm}
        onClose={() => setShowTransmissionPostForm(false)}
        fullWidth
      >
        <TransmissionPostForm
          transmission={fakeTransmission}
          primaryMembership={{ id: lead?.helped_membership_id, helper: primary }}
          onclose={() => setShowTransmissionPostForm(false)}
        />
      </Dialog>
    </>
  );
};

export default CardLeadTransmissionCreation;
