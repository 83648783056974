import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import LeadsActions from '../../Redux/LeadsRedux';
import LeadPostForm from '../Posts/LeadForm';
import CommentPost from '../Posts/CommentPost';

type Props = {
  lead: Object,
  canShowFinalStep: Object,
  currentUser: Object,
  canPropose: Boolean,
};

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const LeadComments = ({ lead, canShowFinalStep, currentUser, canPropose }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleProposeWithComment = (event, post) => {
    const updatedLead = { ...lead, responsible_id: currentUser?.id, validated: true };
    dispatch(
      LeadsActions.updateOrganisationLeadAndPostCommentRequest(
        lead?.organisation?.holding_slug,
        lead?.organisation?.slug,
        updatedLead,
        post,
      ),
    );
    if (canShowFinalStep) canShowFinalStep.current = true;
  };

  return (
    <div className={classes.section}>
      <Grid container>
        <Typography variant="h4" component="h2" gutterBottom>
          {t('APP.POST.COMMENT_COUNT', {
            count: lead?.posts?.length,
            context: `${lead?.posts?.length}`,
          })}
        </Typography>
        <Typography gutterBottom style={{ marginLeft: 8 }}>
          {t('APP.LEADS.COMMENT')}
        </Typography>
      </Grid>
      <Divider className={classes.divider} />
      <Grid>
        {(lead?.posts?.sort((a, b) => a.id - b.id) || []).map(post => (
          <div key={post.id}>
            <CommentPost post={post} />
          </div>
        ))}
      </Grid>
      <Grid container className={classes.section}>
        <LeadPostForm
          key={`comment-lead-form-${lead?.id}-${lead?.posts?.length}`}
          holdingSlug={lead?.organisation?.holding_slug}
          organisationSlug={lead?.organisation?.slug}
          leagueId={lead?.league_id}
          postableId={lead?.id}
          postableType={lead?.type}
          dialogConfirm={
            canPropose && !lead?.responsible_id
              ? {
                  title: t('APP.LEADS.CONFIRM_DIALOG.TITLE'),
                  yesButtonLabel: t('APP.LEADS.CONFIRM_DIALOG.YES'),
                  noButtonLabel: t('APP.LEADS.CONFIRM_DIALOG.NO'),
                  onConfirm: handleProposeWithComment,
                }
              : null
          }
        />
      </Grid>
    </div>
  );
};

export default LeadComments;
