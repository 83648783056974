import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { green, orange } from '@material-ui/core/colors';
import { ASK_FOR } from '../../Services/LeadHelper';
import { getExpertiseStyle } from '../../Themes/Expertises';
import { WelloIcon } from '../../Themes/Icon';
import LeadsActions from '../../Redux/LeadsRedux';

type Props = {
  lead: Object,
  canShowFinalStep: Object,
  currentUser: Object,
  canPropose: Boolean,
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 600,
  },
  serviceIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: theme.spacing(2),
    height: 50,
    width: 50,
    '&>*': {
      height: 50,
      width: 50,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: theme.current.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
  constrainedText: {
    wordWrap: 'break-word',
  },
  confirmed: {
    color: green[600],
  },
  pending: {
    color: orange[600],
  },
  wrap: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
}));

const LeadHeader = ({ lead, currentUser, canShowFinalStep, canPropose }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleProposeMyselfButtonClick = () => {
    const updatedLead = {
      ...lead,
      responsible_id: currentUser.id,
      validated: true, // always accept lead directly
    };
    dispatch(
      LeadsActions.updateOrganisationLeadRequest(
        lead?.organisation?.holding_slug,
        lead?.organisation?.slug,
        updatedLead,
      ),
    );
    if (canShowFinalStep) canShowFinalStep.current = true;
  };

  const defaultTitle =
    lead?.ask_for === ASK_FOR.ME
      ? t('APP.LEADS.DETAILS.TITLE', {
          serviceLabel: lead?.service?.label ? lead?.service?.label : t('LEAD'),
          helperName: lead.full_name
            ? lead.full_name
            : `${lead?.helper_first_name} ${lead?.helper_last_name}`,
        })
      : t('APP.LEADS.DETAILS.TITLE2', {
          serviceLabel: lead?.service?.label ? lead?.service?.label : t('LEAD'),
          helpedName: lead?.helped_full_name,
        });
  return (
    <Grid container style={{ alignItems: 'flex-end' }}>
      {/* TITLE PANEL */}
      <Grid item sm={10} xs={12} className={classes.wrap}>
        {lead?.service ? (
          <>
            <Avatar
              alt={lead?.service?.label}
              className={classes.serviceIcon}
              style={{
                color: lead?.service?.slug
                  ? getExpertiseStyle(lead?.service?.expertise).color
                  : '#ccc',
                backgroundColor: 'white',
              }}
            >
              {lead?.service?.slug ? (
                getExpertiseStyle(lead?.service?.expertise).icon
              ) : (
                <WelloIcon />
              )}
            </Avatar>
            <Typography variant="h3" component="h1" className={classes.bold} itemProp="name">
              {defaultTitle}
            </Typography>
          </>
        ) : (
          <Typography variant="h3" component="h1" className={classes.bold} itemProp="name">
            {t(`APP.LEADS.${lead?.type}`)}
          </Typography>
        )}
      </Grid>
      {/* REGISTER PANEL */}
      <Grid item sm={2} xs={12} className={classes.buttonContainer}>
        {canPropose && !lead?.responsible_id && !lead?.archived && (
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleProposeMyselfButtonClick}
          >
            {lead?.service?.performed_by === 'volunteer'
              ? t('APP.LEADS.ACTION.PROPOSE_MYSELF')
              : t('APP.LEADS.ACTION.CONFIRM')}
          </Button>
        )}
        <div style={{ flexDirection: 'column' }}>
          {lead?.responsible_id && (
            <Typography
              className={classNames(
                classes.constrainedText,
                lead?.validated ? classes.confirmed : classes.pending,
              )}
            >
              {t(
                lead?.validated
                  ? 'APP.LEADS.STATE.RESPONSIBLE_VALIDATED'
                  : 'APP.LEADS.STATE.RESPONSIBLE_PENDING',
                { firstName: lead?.responsible?.fullname || lead?.responsible?.first_name },
              )}
            </Typography>
          )}
          {lead?.archived && (
            <Typography className={classNames(classes.constrainedText)}>
              {t('APP.LEADS.ARCHIVED')}
            </Typography>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default LeadHeader;
