import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LeadsActions from '../../Redux/LeadsRedux';
import PostForm from './Form';

type Props = {
  holdingSlug: String,
  organisationSlug: String,
  postableId: Number,
  postableType: String,
  dialogConfirm: Object,
  leagueId: Number,
};

const LeadPostForm = ({
  holdingSlug,
  organisationSlug,
  leagueId,
  postableId,
  postableType,
  dialogConfirm,
}: Props) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(state => state.auth.currentUser);
  const creatingPost = useSelector(state => state.leads.isFetching.createLeadPost);

  const createPost = post => {
    dispatch(
      LeadsActions.createLeadPostRequest(leagueId, holdingSlug, organisationSlug, postableId, post),
    );
  };

  return (
    <PostForm
      currentUser={currentUser}
      creatingPost={creatingPost}
      createPost={createPost}
      postableType={postableType}
      dialogConfirm={dialogConfirm}
    />
  );
};

export default LeadPostForm;
