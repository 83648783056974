import React from 'react';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import { green, orange } from '@material-ui/core/colors';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconRepit from '@material-ui/icons/AccessTime';
import IconMessage from '@material-ui/icons/Message';
import IconDateRange from '@material-ui/icons/DateRange';
import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';

const styles = theme => ({
  bold: {
    fontWeight: 600,
  },
  media: {
    objectFit: 'cover',
    maxHeight: '100px',
    borderRadius: '4px',
  },
  avatarCard: {
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
  },
  avatarHeader: {
    padding: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '40px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(0),
  },
  button: {
    backgroundColor: theme.current.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
  resumeInfo: {
    padding: theme.spacing(2),
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  constrainedText: {
    wordWrap: 'break-word',
  },
  confirmed: {
    color: green[600],
  },
  pending: {
    color: orange[600],
  },
  list: {
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '0px',
  },
  listItem: {
    marginRight: '3px',
    paddingLeft: '3px',
    minWidth: '40px',
  },
  listItemIcon: {
    color: theme.current.primary,
    // minWidth: '150px',
  },
  listItemText: {
    marginLeft: theme.spacing(2),
  },
  icons: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
});

type Props = {
  lead: Object,
  classes: Object,
};

function ShowLeadRepit({ lead, classes }: Props) {
  const { t } = useTranslation();

  const DURATION_AVAILABLE = {
    hours: t('UNA.QUOTATION.REPIT.DURATION1.LABEL'),
    prolonged: t('UNA.QUOTATION.REPIT.DURATION2.LABEL'),
    question: t('UNA.QUOTATION.REPIT.DURATION3.LABEL'),
  };

  return (
    <>
      {/* <Typography variant="h5">Demande de repit</Typography> */}
      {lead.meta.duration && (
        <ListItem className={classes.list}>
          <ListItemIcon className={classes.listItem}>
            <IconRepit />
          </ListItemIcon>
          <ListItemText
            className={classes.listItem}
            primary={DURATION_AVAILABLE[lead.meta.duration]}
          />
        </ListItem>
      )}
      {lead.meta.more_about && (
        <ListItem className={classes.list}>
          <ListItemIcon className={classes.listItem}>
            <IconRepit style={{ verticalAlign: 'middle' }} />
          </ListItemIcon>
          <ListItemText className={classes.listItem} primary={lead.meta.more_about} />
        </ListItem>
      )}
      {lead.meta.relation_ship && (
        <ListItem className={classes.list}>
          <ListItemIcon className={classes.listItem}>
            <IconRepit style={{ verticalAlign: 'middle' }} />
          </ListItemIcon>
          <ListItemText className={classes.listItem} primary={lead.meta.relation_ship} />
        </ListItem>
      )}
      {lead.description && (
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <IconMessage />
          </ListItemIcon>
          <ListItemText primary={lead.description} />
        </ListItem>
      )}
      {lead.recurrence && (
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <IconDateRange />
          </ListItemIcon>
          <ListItemText primary={lead.recurrence} />
        </ListItem>
      )}
    </>
  );
}

export default withStyles(styles)(ShowLeadRepit);
