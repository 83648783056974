import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import PinDropRounded from '@material-ui/icons/PinDropRounded';
import IconEmail from '@material-ui/icons/Email';
import IconPhone from '@material-ui/icons/Phone';
import IconCake from '@material-ui/icons/Cake';
import IconPerson from '@material-ui/icons/Person';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';
import ErrorIcon from '@material-ui/icons//Error';
import Paper from '@material-ui/core/Paper';
import { red } from '@material-ui/core/colors';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import { ASK_FOR, isSharedLead } from '../../Services/LeadHelper';
import WelloAvatar from '../Shared/WelloAvatar';
import { CardContent, Dialog } from '@material-ui/core';
import LeadMap from './LeadMap';
import { isServiceTransport } from '../../Acl/Controls';
import InfoIcon from '@material-ui/icons/Info';

type Props = {
  lead: Object,
  showProSection: Boolean,
  membershipUrl: String,
};

const useStyles = makeStyles(theme => ({
  avatarCard: {
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
  },
  avatarHeader: {
    padding: theme.spacing(1, 0),
  },
  helpertitle: {
    margin: theme.spacing(2, 0, 1, 0),
  },
  resumeInfo: {
    padding: theme.spacing(2),
    flexDirection: 'column',
  },
  list: {
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '0px',
  },
  listItem: {
    marginRight: '3px',
    paddingLeft: '3px',
    paddingTop: '5px',
    minWidth: '40px',
  },
  button: {
    backgroundColor: theme.current.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
    marginTop: theme.spacing(2),
  },
  linkButton: {
    padding: 0,
    textTransform: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  map: {
    padding: 0,
    height: 600,
    width: 800,
    [theme.breakpoints.down('sm')]: {
      height: '80vh',
      width: '80vw',
    },
  },
}));

const LeadResume = ({ lead, showProSection, membershipUrl }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [showMapDialog, setShowMapDialog] = React.useState(false);

  const createdAtDate = new Date(lead?.created_at);
  const helperBirthday = lead?.helper?.birthday || lead?.birthday;

  const renderAddress = () => {
    return (
      <ListItem className={classes.list}>
        <ListItemIcon className={classes.listItem}>
          <PinDropRounded />
        </ListItemIcon>
        <ListItemText className={classes.listItem}>
          <Typography variant="subtitle2" component="p">{`${lead?.formatted_address}${
            lead?.address_supplement ? `  (${lead?.address_supplement})` : ''
          }`}</Typography>
          {!isServiceTransport(lead?.service) && (
            <Button className={classes.linkButton} onClick={() => setShowMapDialog(true)}>
              {t('SHOW_MAP')}
            </Button>
          )}
        </ListItemText>
      </ListItem>
    );
  };

  const renderError = () => {
    return (
      <ListItem className={classes.list}>
        <ListItemIcon className={classes.listItem} style={{ color: red[600] }}>
          <Tooltip title={t('APP.ORGANISATION.SETTINGS.MEMBERS.VALIDATE.TOOLTIP')} placement="top">
            <ErrorIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText className={classes.listItem}>
          <Typography variant="subtitle2" component="p">
            {t('APP.ORGANISATION.SETTINGS.MEMBERS.MEMBER_TO_VALIDATE')}
          </Typography>
        </ListItemText>
      </ListItem>
    );
  };

  return (
    <Paper>
      <Grid container className={classes.resumeInfo}>
        <Card className={classes.avatarCard}>
          <CardHeader
            className={classes.avatarHeader}
            avatar={
              <WelloAvatar
                avatarUrl={lead?.helper?.avatar_url}
                firstName={lead?.helper_first_name || lead?.helper_full_name || lead?.full_name}
                lastName={lead?.helper_last_name}
                backgroundColor={lead?.helper_avatar_background_color}
                avatarClass={classes.avatar}
                size={36}
              />
            }
            title={
              lead.full_name
                ? lead.full_name
                : `${lead?.helper_first_name} ${lead?.helper_last_name}`
            }
            subheader={
              <Typography variant="subtitle2" component="p">
                {'Le '}
                {moment(createdAtDate).format('LL')}
                {' à '}
                {moment(createdAtDate).format('LT')}
              </Typography>
            }
          />
        </Card>
        <Typography variant="h6" component="p" className={classes.helpertitle}>
          {t(isSharedLead(lead) ? 'APP.LEADS.SHARED.CONTACT.TITLE' : 'APP.LEADS.ME.TITLE')}
        </Typography>
        {lead.meta.helper_type && (
          <ListItem className={classes.list}>
            <ListItemIcon className={classes.listItem}>
              <IconPerson />
            </ListItemIcon>
            <ListItemText className={classes.listItem}>
              <Typography variant="subtitle2" component="p">
                {t(
                  `APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_PROFILE.${lead.meta.helper_type.toUpperCase()}`,
                )}
              </Typography>
            </ListItemText>
          </ListItem>
        )}
        {lead?.phone && (
          <ListItem className={classes.list}>
            <ListItemIcon className={classes.listItem}>
              <IconPhone />
            </ListItemIcon>
            <ListItemText className={classes.listItem}>
              <Typography variant="subtitle2" component="p">
                {lead?.phone}
              </Typography>
            </ListItemText>
          </ListItem>
        )}
        {lead?.email && (
          <ListItem className={classes.list}>
            <ListItemIcon className={classes.listItem}>
              <IconEmail />
            </ListItemIcon>
            <ListItemText className={classes.listItem}>
              <Typography variant="subtitle2" component="p">
                {lead?.email}
              </Typography>
            </ListItemText>
          </ListItem>
        )}
        {lead?.ask_for === ASK_FOR.ME && helperBirthday && (
          <ListItem className={classes.list}>
            <ListItemIcon className={classes.listItem}>
              <IconCake />
            </ListItemIcon>
            <ListItemText className={classes.listItem}>
              <Typography variant="subtitle2" component="p">
                {moment().diff(helperBirthday, 'years')} {t('YEARS_OLD')} (
                {moment(helperBirthday).format('L')})
              </Typography>
            </ListItemText>
          </ListItem>
        )}
        {lead?.ask_for === ASK_FOR.ME &&
          lead?.formatted_address &&
          lead?.formatted_address !== 'NA' &&
          renderAddress()}
        {lead?.ask_for === ASK_FOR.ME && lead?.helper_pathology && (
          <ListItem className={classes.list}>
            <ListItemIcon className={classes.listItem}>
              <FilterVintageIcon />
            </ListItemIcon>
            <ListItemText className={classes.listItem}>
              <Typography variant="subtitle2" component="p">
                {lead?.helper_pathology}
              </Typography>
            </ListItemText>
          </ListItem>
        )}
        {lead?.ask_for === ASK_FOR.ME &&
          lead?.helped_membership_validated === false &&
          showProSection &&
          renderError()}
        {lead?.ask_for !== ASK_FOR.ME && lead?.helped_full_name && (
          <>
            <Typography variant="h6" component="p" className={classes.helpertitle}>
              {t('APP.LEADS.HELPER.TITLE')}
            </Typography>
            <ListItem className={classes.list}>
              <ListItemIcon className={classes.listItem}>
                {!lead?.helped && <IconPerson />}
                {lead?.helped && (
                  <WelloAvatar
                    avatarUrl={lead?.helped?.avatar_url}
                    firstName={lead?.helped?.first_name}
                    lastName={lead?.helped?.last_name}
                    backgroundColor={lead?.helped?.avatar_background_color}
                    avatarStyle={{ width: 24, height: 24, fontSize: '0.8rem' }}
                    size={36}
                  />
                )}
              </ListItemIcon>
              <ListItemText className={classes.listItem}>
                <Typography
                  variant="subtitle2"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: t('APP.LEADS.FIELDS.HELPED_FULL_NAME', {
                      helpedFullName: `<b>${lead?.helped_full_name}</b>`,
                    }),
                  }}
                />
              </ListItemText>
            </ListItem>
            {lead?.birthday && (
              <ListItem className={classes.list}>
                <ListItemIcon className={classes.listItem}>
                  <IconCake />
                </ListItemIcon>
                <ListItemText className={classes.listItem}>
                  <Typography variant="subtitle2" component="p">
                    {moment().diff(lead?.birthday, 'years')} {t('YEARS_OLD')} (
                    {moment(lead?.birthday).format('L')})
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {lead?.formatted_address && renderAddress()}
            {lead?.helped?.phone && (
              <ListItem className={classes.list}>
                <ListItemIcon className={classes.listItem}>
                  <IconPhone />
                </ListItemIcon>
                <ListItemText className={classes.listItem}>
                  <Typography variant="subtitle2" component="p">
                    {lead?.helped.phone}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {lead?.helped_pathology && (
              <ListItem className={classes.list}>
                <ListItemIcon className={classes.listItem}>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText className={classes.listItem}>
                  <Typography variant="subtitle2" component="p">
                    {lead?.helped_pathology}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {lead?.helped_membership_validated === false && showProSection && renderError()}
          </>
        )}
        {membershipUrl && (
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => history.push(membershipUrl)}
            size="small"
          >
            {t('APP.LEADS.SHOW_PRIMARY.LABEL')}
          </Button>
        )}
      </Grid>
      <Dialog open={showMapDialog} onClose={() => setShowMapDialog(false)} maxWidth="md">
        <CardContent className={classes.map}>
          <LeadMap lead={lead} height={600} isDialog />
        </CardContent>
      </Dialog>
    </Paper>
  );
};

export default LeadResume;
