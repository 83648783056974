import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconMessage from '@material-ui/icons/Message';
import IconLocation from '@material-ui/icons/LocationOn';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import IconCake from '@material-ui/icons/Cake';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';
import PageviewIcon from '@material-ui/icons/Pageview';

import { getOrgaName } from '../../../Services/OrganisationHelper';

const useStyles = makeStyles(theme => ({
  listItem: {
    marginRight: '3px',
    paddingLeft: '3px',
    minWidth: '40px',
    color: theme.current.primary,
    // minWidth: '150px',
  },
}));

type Props = {
  lead: Object,
};

function ShowLeadNonQualified(props: Props) {
  const { lead } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid container className={classes.root} spacing={2}>
        {lead.sharer && (
          <ListItem>
            <ListItemIcon className={classes.listItem}>
              <LocationCityIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('APP.LEADS.SHARED.SHARER_NAME', { sharer: getOrgaName(lead.sharer) })}
            />
          </ListItem>
        )}
        {lead.description && (
          <ListItem>
            <ListItemIcon className={classes.listItem}>
              <IconMessage />
            </ListItemIcon>
            <ListItemText primary={lead.description} />
          </ListItem>
        )}
        {lead.meta.page && (
          <ListItem>
            <ListItemIcon className={classes.listItem}>
              <PageviewIcon />
            </ListItemIcon>
            <ListItemText primary={lead.meta.page} />
          </ListItem>
        )}
        {lead.meta.departement && (
          <ListItem>
            <ListItemIcon className={classes.listItem}>
              <IconLocation />
            </ListItemIcon>
            <ListItemText primary={lead.meta.departement} />
          </ListItem>
        )}
        {lead.meta.ville && (
          <ListItem>
            <ListItemIcon className={classes.listItem}>
              <IconLocation />
            </ListItemIcon>
            <ListItemText primary={lead.meta.ville} />
          </ListItem>
        )}
        {lead.meta.helper_situation && (
          <ListItem>
            <ListItemIcon className={classes.listItem}>
              <FilterVintageIcon />
            </ListItemIcon>
            <ListItemText
              primary={t(
                `APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_SITUATION.${lead.meta.helper_situation.toUpperCase()}`,
              )}
            />
          </ListItem>
        )}
        {lead.meta.helper_age && (
          <ListItem>
            <ListItemIcon className={classes.listItem}>
              <IconCake />
            </ListItemIcon>
            <ListItemText
              primary={t(
                `APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_AGE.${lead.meta.helper_age.toUpperCase()}`,
              )}
            />
          </ListItem>
        )}
        {lead.meta.helper_needs && (
          <ListItem>
            <ListItemIcon className={classes.listItem}>
              <ShoppingBasketIcon />
            </ListItemIcon>
            <ListItemText
              primary={t(
                `APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_NEEDS.${lead.meta.helper_needs.toUpperCase()}`,
              )}
            />
          </ListItem>
        )}
      </Grid>
    </>
  );
}

export default ShowLeadNonQualified;
