import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import IconMessage from '@material-ui/icons/Message';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconDateRange from '@material-ui/icons/DateRange';
import IconPeople from '@material-ui/icons/People';
import IconArrival from '@material-ui/icons/PinDropRounded';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LeadMap from '../../../Components/Leads/LeadMap';

const useStyles = makeStyles(theme => ({
  listItem: {
    marginRight: '3px',
    paddingLeft: '3px',
    minWidth: '40px',
  },
  listItemIcon: {
    color: theme.current.primary,
  },
}));

type Props = {
  lead: Object,
};

function ShowLeadTransport({ lead }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  /* eslint-disable-next-line */
  const momentDuration = !isNaN(parseFloat(lead.meta.duration, 10))
    ? moment.duration(parseFloat(lead.meta.duration, 10), 'hours')
    : null;

  return (
    <>
      <Grid item md={12}>
        <LeadMap lead={lead} />
      </Grid>
      <Typography variant="subtitle1" component="p">
        {t('APP.LEADS.TRANSPORT.DEPARTURE.TITLE')}
      </Typography>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={6}>
          <ListItem>
            <ListItemIcon className={classes.listItemIcon}>
              <IconArrival style={{ color: '#0E61C2' }} />
            </ListItemIcon>
            <ListItemText
              className={classes.listItem}
              primary={lead.meta.departureFormattedAddress}
            />
          </ListItem>
        </Grid>
        <Grid item xs={6}>
          <ListItem>
            <ListItemIcon className={classes.listItemIcon}>
              <IconDateRange />
            </ListItemIcon>
            <ListItemText
              className={classes.listItem}
              primary={moment(lead.meta.departure_date).format('LLL')}
            />
          </ListItem>
        </Grid>
      </Grid>
      <Typography variant="subtitle1" component="p">
        {t('APP.LEADS.TRANSPORT.ARRIVAL.TITLE')}
      </Typography>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={6}>
          <ListItem>
            <ListItemIcon className={classes.listItemIcon}>
              <IconArrival style={{ color: '#5FAF01' }} />
            </ListItemIcon>
            <ListItemText
              className={classes.listItem}
              primary={lead.meta.arrivalFormattedAddress}
            />
          </ListItem>
        </Grid>
        <Grid item xs={6}>
          <ListItem>
            <ListItemIcon className={classes.listItemIcon}>
              {/* {t('APP.SERVICE.TEXT_FIELD.LABEL.RETURN_DATE')} */}
              <IconDateRange />
            </ListItemIcon>
            {lead.meta.return_date && (
              <ListItemText
                className={classes.listItem}
                primary={moment(lead.meta.return_date).format('LLL')}
              />
            )}
            {!lead.meta.return_date && t('APP.LEADS.TRANSPORT.NO_ARRIVAL.TITLE')}
          </ListItem>
        </Grid>
      </Grid>
      <Typography variant="subtitle1" component="p">
        Autres
      </Typography>
      <List component="nav" className={classes.root} aria-label="Transport">
        <ListItem>
          <ListItemIcon className={classes.listItemIcon}>
            <IconPeople />
          </ListItemIcon>
          <ListItemText
            className={classes.listItem}
            primary={`${lead.meta.passager_count} passager${
              lead.meta.passager_count > 1 ? 's' : ''
            }`}
          />
        </ListItem>
        {lead.description && (
          <ListItem>
            <ListItemIcon className={classes.listItemIcon}>
              <IconMessage />
            </ListItemIcon>
            <ListItemText className={classes.listItem} primary={lead.description} />
          </ListItem>
        )}
        {lead?.meta?.round_trip && (
          <ListItem>
            <ListItemIcon className={classes.listItemIcon}>
              <Typography variant="body1">
                {t('APP.SERVICE.TEXT_FIELD.LABEL.ROUNDTRIP_INFO')} : {t('YES')}
              </Typography>
            </ListItemIcon>
          </ListItem>
        )}
        <ListItem>
          <ListItemIcon className={classes.listItemIcon}>
            <Typography variant="body1">
              {t('APP.SERVICE.TEXT_FIELD.LABEL.NEED_ACCOMPAGNEMENT')} :
            </Typography>
          </ListItemIcon>
          <ListItemText className={classes.listItem}>
            {momentDuration && (
              <Typography variant="body1">
                {momentDuration.hours() > 0 &&
                  momentDuration.hours() < 2 &&
                  `${momentDuration.hours()}h`}
                {momentDuration.minutes() > 0 && `${momentDuration.minutes()}min`}
                {momentDuration.hours() >= 2 && t('APP.QUOTATION.TRANSPORT.DURATION4.LABEL')}
              </Typography>
            )}
            {!momentDuration && <Typography variant="body1">{t('NO')}</Typography>}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listItemIcon}>
            <Typography variant="body1">
              {t('APP.SERVICE.TEXT_FIELD.LABEL.ESTIMATED_PRICE')} :
            </Typography>
          </ListItemIcon>
          <ListItemText className={classes.listItem}>
            {lead.meta.estimatedPrice && (
              <Typography variant="body1">
                {parseFloat(lead.meta.estimatedPrice, 10).toFixed(2)} &euro;
              </Typography>
            )}
            {!lead.meta.estimatedPrice && <Typography variant="body1">{t('NO')}</Typography>}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Typography variant="body1">
              {t('MORMAL.SEARCH_RESULT_PAGE.DISTANCE_DETAIL', {
                distance: lead.meta?.distance,
              })}
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Typography variant="body1">
              {t('MORMAL.SEARCH_RESULT_PAGE.DURATION_DETAIL', {
                duration: lead.meta?.trip_duration,
              })}
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
}

export default ShowLeadTransport;
