import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconEmail from '@material-ui/icons/Email';
import IconPhone from '@material-ui/icons/Phone';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import WelloAvatar from '../../Components/Shared/WelloAvatar';

const useStyles = makeStyles(theme => ({
  avatarCard: {
    padding: theme.spacing(2),
  },
  avatarHeader: {
    padding: theme.spacing(1),
  },
  contactHelper: {
    margin: theme.spacing(1, 0),
  },
  list: {
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '0px',
  },
  listItem: {
    marginRight: '3px',
    paddingLeft: '3px',
    minWidth: '40px',
  },
}));

type Props = {
  lead: Object,
};

const LeadResumeFamily = ({ lead }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid>
      {lead?.responsible_id && (
        <Card className={classes.avatarCard}>
          <CardHeader
            className={classes.avatarHeader}
            avatar={
              <WelloAvatar
                avatarUrl={lead?.responsible?.avatar_url}
                firstName={lead?.responsible?.first_name}
                lastName={lead?.responsible?.last_name}
                backgroundColor={lead?.responsible?.avatar_background_color}
                avatarClass={classes.avatar}
                size={36}
              />
            }
            title={`${lead?.responsible?.first_name} ${lead?.responsible?.last_name}`}
          />

          <Typography variant="subtitle2" component="p">
            {lead?.responsible?.description}
          </Typography>
          {(lead?.responsible?.phone || lead?.responsible?.email) && (
            <Typography variant="h6" component="p" className={classes.contactHelper}>
              {t('APP.LEADS.CONTACT.TITLE')}
            </Typography>
          )}
          {lead?.responsible?.phone && (
            <ListItem className={classes.list}>
              <ListItemIcon className={classes.listItem}>
                <IconPhone />
              </ListItemIcon>
              <ListItemText className={classes.listItem}>
                <Typography variant="subtitle2" component="p">
                  {lead?.responsible?.phone}
                </Typography>
              </ListItemText>
            </ListItem>
          )}
          {lead?.responsible?.email && (
            <ListItem className={classes.list}>
              <ListItemIcon className={classes.listItem}>
                <IconEmail />
              </ListItemIcon>
              <ListItemText className={classes.listItem}>
                <Typography variant="subtitle2" component="p">
                  {lead?.responsible?.email}
                </Typography>
              </ListItemText>
            </ListItem>
          )}
        </Card>
      )}
      {!lead?.responsible_id && (
        <Card className={classes.avatarCard}>{t('APP.LEADS.NOT_ARCHIVED')}</Card>
      )}
    </Grid>
  );
};

export default LeadResumeFamily;
