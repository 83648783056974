import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import ShowLeadTransport from '../../Organisations/Leads/ShowLeadTransport';
import ShowLeadRepit from '../../Organisations/Leads/ShowLeadRepit';
import ShowLeadSap from '../../Organisations/Leads/ShowLeadSap';
import ShowLeadNonQualified from '../../Organisations/Leads/ShowLeadNonQualified';
import LeadsActions from '../../../Redux/LeadsRedux';
import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import { isServiceTransport, isServiceSap, isServiceRepit } from '../../../Acl/Controls';
import NoMatch404 from '../../NoMatch404';
import CardLeadTransmissionCreation from '../../../Components/Organisations/Transmission/CardLeadTransmissionCreation';
import LeadComments from '../../../Components/Leads/LeadComments';
import LeadResume from '../../../Components/Leads/LeadResume';
import LeadHeader from '../../../Components/Leads/LeadHeader';
import CardHoldingSimplified from '../../../Components/Organisations/Shared/CardHoldingSimplified';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 2px 3px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12)',
  },
  headerLayout: {
    ...LayoutStyles.fullWidthLayout,
    width: '100%',
    padding: '20px',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    width: 'inherit',
    paddingTop: '40px',
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
    },
  },
  leftContent: {
    order: 2,
    [theme.breakpoints.up(`md`)]: {
      order: 1,
    },
  },
  rightContent: {
    order: 1,
    [theme.breakpoints.up(`md`)]: {
      position: 'sticky',
      top: '4rem',
      order: 2,
    },
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonLink: {
    textTransform: 'none',
    padding: theme.spacing(0, 0, 2),
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  holdingCardTitle: {
    margin: theme.spacing(2, 0),
  },
}));

const ShowLead = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { lead_id: leadId } = match.params;
  const league = useSelector(state => state.leagues.league);
  const currentUser = useSelector(state => state.auth.currentUser);
  const lead = useSelector(state => state.leads.lead);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const errors = useSelector(state => state.leads.errors.getLead);
  const leadReady = `${lead?.id}` === `${leadId}`;

  React.useEffect(() => {
    if (!league) return;

    dispatch(LeadsActions.getLeagueLeadRequest(league?.id, leadId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [league?.id, leadId]);

  if (errors && errors.status === 404) return <NoMatch404 />;

  if (!leadReady) {
    return null;
  }

  const isTransport = isServiceTransport(lead?.service);
  const isSap = isServiceSap(lead?.service);
  const isRepit = isServiceRepit(lead?.service);

  return (
    <>
      <Grid container>
        {/* HEADER SECTION */}
        <Grid container className={classes.headerContainer}>
          <Grid className={classes.headerLayout}>
            <Button
              color="primary"
              component={Link}
              to={`/leagues/${league.id}/helpers/${lead.helper.id}`}
              className={classes.buttonLink}
              disableTouchRipple
            >
              <ArrowLeftIcon />
              {t('APP.LEADS.BACK')}
            </Button>
            {lead && <LeadHeader lead={lead} currentUser={currentUser} />}
          </Grid>
        </Grid>
        {/* CONTENT SECTION */}
        <Grid container reverse="true" spacing={8} className={classes.layout}>
          {/* CONTENT PANEL */}
          <Grid item md={8} xs={12} className={classes.leftContent}>
            {/* DESCRIPTION PANEL */}
            <div className={classes.section}>
              <Typography variant="h4" component="h2" gutterBottom>
                {lead?.service?.label
                  ? t('APP.LEADS.QUALIFIED_TITLE', { leadLabel: lead?.service?.label })
                  : t(`APP.LEADS.TITLE.${lead?.type}`)}
              </Typography>
              <Divider className={classes.divider} />
              {isTransport && <ShowLeadTransport lead={lead} />}
              {isSap && <ShowLeadSap lead={lead} />}
              {isRepit && <ShowLeadRepit lead={lead} />}
              {/* NON_QUALIFIED */}
              {!isTransport && !isSap && !isRepit && <ShowLeadNonQualified lead={lead} />}
              {/* COMMENT PANEL */}
              {lead && isLoggedIn && <LeadComments lead={lead} currentUser={currentUser} />}
            </div>
          </Grid>
          {/* RESUME PANEL */}
          <Grid item md={4} xs={12} className={classes.rightContent}>
            {lead && <LeadResume lead={lead} />}
            {lead && lead?.helped_membership_id && currentUser?.id === lead?.responsible_id && (
              <CardLeadTransmissionCreation lead={lead} />
            )}
            <Typography paragraph variant="body1" className={classes.holdingCardTitle}>
              {t('APP.LEADS.ORGA')}
            </Typography>
            <CardHoldingSimplified
              holding={lead?.holding}
              holdingSlug={lead?.holding?.slug}
              organisationSlug={lead?.organisation?.slug}
              orgaName={lead?.organisation?.name}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ShowLead;
